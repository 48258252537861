* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 17px;
}

html {
	scroll-behavior: smooth;
}

@media (max-width: 768px) {
	.hide-mb {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.hide-pc {
		display: none !important;
	}
}

@media (min-width: 1000px) {
	.hide-pc-1000 {
		display: none !important;
	}
}

.legal-page {
	display: flex;
	flex-direction: column;
	padding: 40px 10%;
	align-items: center;
	.legal-text {
		max-width: 800px;
		display: flex;
		flex-direction: column;
		gap: 10px;

		p {
			text-align: justify;
		}
	}
}
